import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import { Icon } from "../../components/Component";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import { redirect, useLocation } from "react-router";
import AppDropdown from "./dropdown/app/App";
import "../../css/common.css";
// import "../../css/tvsilocloud/app.css";
import silocloudwhite from "../../images/applogos/white_theme/silocloud_white.png";
import silocloudblack from "../../images/applogos/black_theme/silocloud_black.png";
import TVlogo from "../../images/applogos/tv_logo.png";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { appLogoDark, appLogoLight } from "../../images/applogos";
import SpeechMic from "./SpeechMic";

function redirectLogin() {
  let at = Cookies.get("authToken");
  if (!at) {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    window.location = process.env.REACT_APP_ACCOUNT_URL;
  } else {
    Cookies.remove("currentURL", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  }
}

const Header = ({ fixed, className }) => {
  const theme = useTheme();
  const { skin } = useTheme();
  const themeUpdate = useThemeUpdate();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]:
      theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  const location = useLocation();
  // const [fullScreenOn, setFullScreenOn] = useState(false);
  const toggleFullScreen = () => {
    // setFullScreenOn(!fullScreenOn);
    themeUpdate.fullScreen(!theme.fullScreen);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  // State for time and date
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${month}/${date}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        themeUpdate.fullScreen(true);
      } else {
        themeUpdate.fullScreen(false);
      }
    });
    return document.removeEventListener("fullscreenchange", () => {});
  }, []);

  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      setIsLogin(true);
    }
  }, []);

  // useEffect(() => {
  //   const fetchTheme = async () => {
  //     try {
  //       const data = new FormData();
  //       data.append("action", "fetch");
  //       const response = await fetchAPI(
  //         data,
  //         "api/v1/public/frontend-settings"
  //       );
  //       if (response.status) {
  //         const fetchedTheme = response?.data?.frontend_settings?.theme;
  //         themeUpdate.skin(fetchedTheme === "1" ? "light" : "dark");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchTheme();
  // }, [fullScreenOn]);

  if (theme.fullScreen || theme.customLayout) {
    return null;
  }

  return (
    <section className="section-header">
      <div className={`${headerClass} border-0`}>
        <div className="container-fluid position-relative">
          <div className="nk-header-wrap align-items-center">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon"
                icon="menu"
                click={themeUpdate.sidebarVisibility}
              />
            </div>
            <div className="nk-header-app-name">
              <div className="header-logo">
                {skin === "light" ? (
                  <img src={appLogoLight} height={"45px"} />
                ) : (
                  <img src={appLogoDark} height={"45px"} />
                )}
              </div>
              <div className="form-control-wrap ms-md-4 ms-1 header-search">
                <div className="form-icon form-icon-left">
                  <Icon name="search" />
                </div>
                <input
                  className="form-control"
                  type="text"
                  id="appsearch"
                  placeholder="Search"
                  style={{ borderRadius: "24px" }}
                />
                <div className="form-icon form-icon-right rounded-5">
                  <SpeechMic />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center header-calendar">
              <div style={{ lineHeight: "17px" }}>
                <p className="mb-0 fw-bolder text-blue-color">{time}</p>
                <p className="fs-9px mb-0">{date}</p>
              </div>
              <div className="ms-1">
                <Link to="https://calendar.silocloud.io/">
                  <Icon name="calender-date" className="head-icon" />
                </Link>
              </div>
            </div>

            <div className="nk-header-tools align-items-center py-1">
              <ul className="nk-quick-nav">
                <li className="mt-1">
                  {theme.fullScreen ? (
                    <Icon
                      name="minimize"
                      className="pointer fs-4"
                      onClick={toggleFullScreen}
                    />
                  ) : (
                    <Icon
                      name="maximize"
                      className="pointer fs-4"
                      onClick={toggleFullScreen}
                    />
                  )}
                </li>
                <li className="notification-dropdown">
                  <Notification />
                </li>
                <li className="notification-dropdown">
                  <AppDropdown />
                </li>
                {isLogin ? (
                  <li className="user-dropdown d-flex">
                    <User />
                  </li>
                ) : (
                  <li className="d-flex justify-content-end align-items-center ">
                    <button
                      className="btn sign-in-btn h-25"
                      onClick={redirectLogin}
                    >
                      Sign In
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Header;
